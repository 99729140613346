import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CreateQuizDialog from '../../features/CreateQuizDialog/CreateQuizDialog';
import { useParams } from 'react-router-dom';
import Icon from '../../components/Icon';

const CreateQuizButton = ({ variant, color, size, sx, onQuizCreated, lastFileUpload, courseName, ...props }) => {
  const [open, setOpen] = useState(false);
  const { courseId } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 

  return (
    <>
      <Button
        variant={variant}
        color={color}
        size={size}
        startIcon={<Icon iconName="quiz" style={{height: 24, weight: 24}}/>}
        onClick={handleClickOpen}
        sx={sx}
        {...props}
      >
        Frageset erstellen
      </Button>
      <CreateQuizDialog open={open} onClose={handleClose} courseId={courseId} onQuizCreated={onQuizCreated} lastUploadTimestamp={lastFileUpload} courseName={courseName}/>
    </>
  );
};

CreateQuizButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  size: 'medium',
};

export default CreateQuizButton;



