import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Icon from '../../components/Icon';
import EditQuestionSetButton from '../Button/EditQuestionSetButton';
import DeleteQuestionSetButton from '../Button/DeleteQuestionSetButton';
import Tooltip from '@mui/material/Tooltip';

const QuizList = ({ questionSets, questionsError, onQuestionSetClick, onQuestionSetUpdated, courseName, isQuizReady }) => {
  return (
    <Paper elevation={4} sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '600px', width: '100%', borderRadius: "20px" }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 1, pb: 2, backgroundColor: 'background.paper', width: '100%' }}>
        {courseName} - Meine Fragesets
      </Typography>
      <Box sx={{
        overflowY: 'auto',
        flexGrow: 1,
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',
          borderRadius: '4px',
        }
      }}>
        {questionSets.length > 0 ? (
          questionSets.sort((a, b) => a.name.localeCompare(b.name)).map((questionSet) => (
            <Box
              key={questionSet.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                p: 2,
                width: '100%',
                '&:hover': {
                  backgroundColor: 'grey.200',
                  cursor: 'pointer'
                }
              }}
              onClick={() => onQuestionSetClick(questionSet)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, maxWidth: 'calc(100% - 48px)', overflow: 'hidden' }}>
  <Icon iconName="quiz" style={{height:30, weight:30}} />
  <Tooltip title={questionSet.name}>
    <span style={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}>
      {questionSet.name}
    </span>
  </Tooltip>
  {questionSet.questions_status === 'in_progress' && (
  <Typography sx={{ ml: 2, color: 'grey.600', textAlign: "left", fontSize: "14px" }}>
    Der Erstellungsprozess deiner Fragen läuft noch. Du kannst dein Frageset aber jetzt schon anschauen.
  </Typography>
)}
</Box>
              <Box sx={{ display: 'flex', gap: 1 }} onClick={(e) => e.stopPropagation()}>
                <EditQuestionSetButton questionSetId={questionSet.id} onQuestionSetUpdated={onQuestionSetUpdated} />
                <DeleteQuestionSetButton questionSetId={questionSet.id} onQuestionSetUpdated={onQuestionSetUpdated} />
              </Box>
            </Box>
          ))
        ) : questionsError ? (
          <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
            Error: {questionsError}
          </Typography>
        ) : (
          <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
            Du hast noch keine Fragestes erstellt. 
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

export default QuizList;
