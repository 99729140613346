import { ReactComponent as AddFolderIcon } from './Add_Folder_Icon.svg';
import { ReactComponent as CheckCircleIcon } from './Check_Circle_Icon.svg';
import { ReactComponent as CoursesIcon } from './Courses_Icon.svg';
import { ReactComponent as CrossCircleIcon } from './Cross_Circle_Icon.svg';
import { ReactComponent as CrossIcon } from './Cross_Icon.svg';
import { ReactComponent as DownloadIcon } from './Download_Icon.svg';
import { ReactComponent as FeedbackIcon } from './Feedback_Icon.svg';
import { ReactComponent as FileIcon } from './File_Icon.svg';
import { ReactComponent as FolderIcon } from './Folder_Icon.svg';
import { ReactComponent as LeftCircleIcon } from './Left_Circle_Icon.svg';
import { ReactComponent as LogoutIcon } from './Logout_Icon.svg';
import { ReactComponent as PaletteIcon } from './Palette_Icon.svg';
import { ReactComponent as PenIcon } from './Pen_Icon.svg';
import { ReactComponent as PinIcon } from './Pin_Icon.svg';
import { ReactComponent as PlusCircleIcon } from './Plus_Circle_Icon.svg';
import { ReactComponent as ProfileIcon } from './Profile_Icon.svg';
import { ReactComponent as QuizIcon } from './Quiz_Icon.svg';
import { ReactComponent as RightCircleIcon } from './Right_Circle_Icon.svg';
import { ReactComponent as ShuffleIcon } from './Shuffle_Icon.svg';
import { ReactComponent as SidebarIcon } from './Sidebar_Icon.svg';
import { ReactComponent as StatisticIcon } from './Statistic_Icon.svg';
import { ReactComponent as TrashIcon } from './Trash_Icon.svg';
import { ReactComponent as UploadIcon } from './Upload_Icon.svg';
import { ReactComponent as UserIcon } from './User_Icon.svg';
import { ReactComponent as PanicButtonIcon } from './Panic_Button.svg';

const Icons = {
  addFolder: AddFolderIcon,
  checkCircle: CheckCircleIcon,
  courses: CoursesIcon,
  crossCircle: CrossCircleIcon,
  cross: CrossIcon,
  download: DownloadIcon,
  feedback: FeedbackIcon,
  file: FileIcon,
  folder: FolderIcon,
  leftCircle: LeftCircleIcon,
  logout: LogoutIcon,
  palette: PaletteIcon,
  panic: PanicButtonIcon,
  pen: PenIcon,
  pin: PinIcon,
  plusCircle: PlusCircleIcon,
  profile: ProfileIcon,
  quiz: QuizIcon,
  rightCircle: RightCircleIcon,
  shuffle: ShuffleIcon,
  sidebar: SidebarIcon,
  statistic: StatisticIcon,
  trash: TrashIcon,
  upload: UploadIcon,
  user: UserIcon,
};

export default Icons;
