import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCourseData } from '../../utils/AuthenticationService';
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import {useTheme} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import UploadFilesField from '../../components/UploadFilesField';
import { fetchFilesByCourse } from '../../services/FileService';
import FilePreviewDrawer from '../../components/FilePreviewDrawer';
import Paper from '@mui/material/Paper';
import SideDrawer from '../../components/Drawer';
import { useSidebar } from '../../contexts/SidebarContext';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import CreateQuizButton from '../../components/Button/CreateQuizButton';
import { fetchQuestionSetsByCourse } from '../../services/QuestionService';
import QuestionSetDetail from '../../components/QuestionSetDetail';
import { useLocation } from 'react-router-dom';
import FileList from '../../components/Lists/FileList';
import QuizList from '../../components/Lists/QuizList';
import usePollFiles from '../../hooks/usePollFiles';
import usePollQuizzes from '../../hooks/usePollQuizzes';



const drawerWidth = 240;

const CoursePage = () => {
  const { isOpen, toggleDrawer } = useSidebar();
  const [courses, setCourses] = useState([]);
  const [files, setFiles] = useState([]);
  const [questionSets, setQuestionSets] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseError, setCourseError] = useState(null);
  const [filesError, setFilesError] = useState(null);
  const [questionsError, setQuestionsError] = useState(null);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);
  const [isViewingQuestionSet, setIsViewingQuestionSet] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [currentFileId, setCurrentFileId] = useState(null);
  const [currentCreationRequestId, setCurrentCreationRequestId] = useState(null);
  const [isQuizReady, setIsQuizReady] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const [lastFileUpload, setLastFileUpload] = useState(Date.now());
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [panicMode, setPanicMode] = useState(false);
  const theme = useTheme();
  const originalGradient = theme.palette.gradient.background;
  const [showPostPanicMessage, setShowPostPanicMessage] = useState(false);
  const [postPanicMessage, setPostPanicMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  

  useEffect(() => {
    if (!courseDetails && !courseError) {
      const timer = setTimeout(() => {
        setShowLoadingMessage(true);
      }, 500); // Adjust delay time as needed
  
      return () => clearTimeout(timer); // Clean up the timer
    } else {
      setShowLoadingMessage(false); // Immediately hide the message if the content is loaded or there is an error
    }
  }, [courseDetails, courseError]);
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlTabValue = params.get('tab');

    if (urlTabValue) {
      setTabValue(urlTabValue);
    }

    const savedQuestionSet = localStorage.getItem('selectedQuestionSet');
    const savedIsViewingQuestionSet = localStorage.getItem('isViewingQuestionSet');

    if (savedQuestionSet && savedIsViewingQuestionSet === 'true') {
      setSelectedQuestionSet(JSON.parse(savedQuestionSet));
      setIsViewingQuestionSet(true);
    }

    const fetchInitialData = async () => {
      setIsLoadingFiles(true);
      try {
        const [courseData, filesData, questionSetsData] = await Promise.all([
          fetchCourseData(courseId),
          fetchFilesByCourse(courseId),
          fetchQuestionSetsByCourse(courseId)
        ]);

        setCourseDetails(courseData);
        setFiles(filesData);
        setQuestionSets(questionSetsData);
        setIsLoadingFiles(false);
      } catch (error) {
        console.error('Failed to load initial data:', error);
        setCourseError('Failed to load course data');
        setIsLoadingFiles(false);
      }
    };

    fetchInitialData();
  }, [courseId, location.search]);


  const handleQuestionSetsRefresh = useCallback(async () => {
    console.log("Refreshing question sets for courseId:", courseId);
    try {
      const updatedQuestionSets = await fetchQuestionSetsByCourse(courseId);
      console.log("Updated question sets:", updatedQuestionSets);
      setQuestionSets(updatedQuestionSets);
    } catch (error) {
      console.error('Error refreshing question sets:', error);
      setQuestionsError('Failed to refresh question sets');
    }
  }, [courseId]);
  

  const handleQuizCreated = useCallback((creationRequestId) => {
    console.log("handleQuizCreated called with creationRequestId:", creationRequestId);
    setCurrentCreationRequestId(creationRequestId);  // Start polling the creation request status
    setIsQuizReady(false);  // Assume it's not ready until confirmed
    handleQuestionSetsRefresh();  // Refresh quiz list immediately
  }, [handleQuestionSetsRefresh]);



  const updateFileInState = (updatedFile) => {
    setFiles(prevFiles => prevFiles.map(file => {
        return file.id === updatedFile.id ? updatedFile : file;
    }));
};

const handleCreationRequestUpdate = (creationRequestData) => {
  console.log("Creation request updated:", creationRequestData);

  const updatedQuestionSetId = creationRequestData.question_set; // The ID of the question set
  const updatedStatus = creationRequestData.status; // The current status

  // Update the specific question set in the questionSets array
  setQuestionSets(prevQuestionSets =>
    prevQuestionSets.map(qs => {
      if (qs.id === updatedQuestionSetId) {
        return { ...qs, questions_status: updatedStatus };
      }
      return qs;
    })
  );

  if (updatedStatus === 'completed') {
    // Creation request is completed
    setIsQuizReady(true);  // The quiz is ready
    setCurrentCreationRequestId(null); // Stop polling
  } else if (updatedStatus === 'failed') {
    // Handle failure
    console.error('Quiz creation failed:', creationRequestData.error_details);
    setQuestionsError('Quiz creation failed');
    setIsQuizReady(false);
    setCurrentCreationRequestId(null); // Stop polling
  } else {
    // Still in progress
    setIsQuizReady(false);
  }
};



usePollFiles(currentFileId, updateFileInState, setFilesError, setCurrentFileId);

usePollQuizzes(currentCreationRequestId, handleCreationRequestUpdate, setQuestionsError, setCurrentCreationRequestId);


if (courseError) {
  return <div>Error: {courseError}</div>;
} else if (!courseDetails) {
  // Here, you can also check showLoadingMessage before showing the loading indicator
  return showLoadingMessage ? <div>Details werden geladen...</div> : null;
}


  const handleFileClick = (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const handleQuestionSetClick = (questionSet) => {
    setSelectedQuestionSet(questionSet);
    setIsViewingQuestionSet(true);
    localStorage.setItem('selectedQuestionSet', JSON.stringify(questionSet));
    localStorage.setItem('isViewingQuestionSet', 'true');
  };

  const handleBackToQuestionSets = () => {
    setIsViewingQuestionSet(false);
    localStorage.removeItem('selectedQuestionSet');
    localStorage.removeItem('isViewingQuestionSet');
  };

  const handleCourseCreated = async () => {
    const fetchedCourses = await fetchCourseData(courseId);
    setCourses(fetchedCourses);
  };

  const handleFileUploadSuccess = async (fileId) => {
    setCurrentFileId(fileId); // Set the current file ID for polling
    const updatedFiles = await fetchFilesByCourse(courseId);
    setFiles(updatedFiles);
    setLastFileUpload(Date.now()); // Update the timestamp when files are updated
  };
 
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`/course/${courseId}?tab=${newValue}`); // Update URL when tab changes
    localStorage.setItem('coursePageTab', newValue);

    if (newValue === '2') {
      setIsViewingQuestionSet(false);
      setSelectedQuestionSet(null);
      localStorage.removeItem('selectedQuestionSet');
      localStorage.removeItem('isViewingQuestionSet');
    }
  };

  // Function to trigger panic mode
const handlePanicClick = () => {
  setPanicMode(true);
  
  // Start random color changing and shaking animations.
  startPanicEffects();

  // After a few seconds, stop panic mode
  setTimeout(() => {
    stopPanicEffects();
    setPanicMode(false);
  }, 4000); // 5 seconds of panic
};

let twistInterval = null;

const postPanicMessages = [
  "Atme durch und leg wieder los!",
  "Puh, kurze Pause und weiter gehts.",
  "Alles gut, weiter geht's!",
  "Tief durchatmen, du schaffst das!",
  "Kurzer Reset - jetzt mit frischer Energie!"
];


const startPanicEffects = () => {
  document.body.classList.add('panic-shake');
  startColorCycle();
  startRandomTwists();
};

const stopPanicEffects = () => {
  document.body.classList.remove('panic-shake');
  stopColorCycle();
  stopRandomTwists();


  // Restore original background if needed
  document.documentElement.style.setProperty('--panic-bg', originalGradient);

  // Show the post-panic message
  const randomMsg = postPanicMessages[Math.floor(Math.random() * postPanicMessages.length)];
  setPostPanicMessage(randomMsg);
  setShowPostPanicMessage(true);
  setTimeout(() => {
    setMessageVisible(true);
  }, 50);

  // Fade out after a few seconds
  setTimeout(() => {
    setMessageVisible(false);
  }, 3000);
  
  setTimeout(() => {
    setShowPostPanicMessage(false);
  }, 4000);
};

const startRandomTwists = () => {
  // Which elements to twist? Let's pick .paper-content, button, and maybe p tags.
  // Increase or modify as desired.
  const selectors = ['.paper-content', 'button', 'p', 'h1', 'h2', 'h3'];
  
  twistInterval = setInterval(() => {
    // For each selector, pick a random set of elements
    selectors.forEach((sel) => {
      const elements = document.querySelectorAll(sel);
      elements.forEach(elem => {
        // 50% chance to transform this element
        if (Math.random() < 0.5) {
          const rotate = Math.floor(Math.random() * 40) - 20; // rotate between -20 and 20 deg
          const scale = 1 + (Math.random() * 0.5 - 0.25); // scale between 0.75 and 1.25
          elem.style.transition = 'transform 0.2s ease';
          elem.style.transform = `rotate(${rotate}deg) scale(${scale})`;
        } else {
          // Reset transform
          elem.style.transform = '';
        }
      });
    });
  }, 300); // Every 300ms, apply a new round of random transformations
};

const stopRandomTwists = () => {
  if (twistInterval) {
    clearInterval(twistInterval);
    twistInterval = null;
  }

  // Reset all transforms
  const allElements = document.querySelectorAll('.paper-content, button, p, h1, h2, h3');
  allElements.forEach(elem => {
    elem.style.transform = '';
    elem.style.transition = '';
  });
};


let hueInterval = null;
let currentHue = 0;

const startColorCycle = () => {

  currentHue = 0;

  hueInterval = setInterval(() => {
    currentHue = (currentHue + 20) % 360; // Increase by 10 degrees each step
    document.body.style.filter = `hue-rotate(${currentHue}deg)`;
  }, 50); // Change hue every 100ms for smoother transition
};

const stopColorCycle = () => {
  if (hueInterval) {
    clearInterval(hueInterval);
    hueInterval = null;
  }
  // Remove the hue-rotate filter at the end
  document.body.style.filter = '';
};





  return (
    <>
    <Box sx={{ background: panicMode ? 'var(--panic-bg)' : theme.palette.gradient.background, minHeight: '100vh', width: '100%' }}>
      <CssBaseline />
      <FilePreviewDrawer open={previewOpen} onClose={() => setPreviewOpen(false)} file={selectedFile} />
      {/*<TopAppBar open={isOpen} toggleDrawer={toggleDrawer} greeting={`Hallo, ${userName}!`} />)*/}
      <SideDrawer open={isOpen} toggleDrawer={toggleDrawer} onCourseCreated={handleCourseCreated} onPanicClick={handlePanicClick}  />
      <Box component="main" sx={{
  flexGrow: 1,
  p: 1,
  width: isOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 72px)`,
  ml: isOpen ? `${drawerWidth}px` : `72px`,
  // Remove direct transform and rely on animation when opening:
  // We'll keep transform at translateX(-5px) when closed and apply animation when open.
  transform: isOpen ? 'none' : 'translateX(-5px)',
  transition: `
    width 500ms cubic-bezier(0.2, 1, 0.2, 1),
    margin 500ms cubic-bezier(0.2, 1, 0.2, 1)
  `,
  backgroundColor: "transparent",
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'space-between',
  // Conditionally add a subtle bounce animation when opening
  ...(isOpen && {
    animation: 'openBounce 500ms cubic-bezier(0.2, 1, 0.2, 1) forwards'
  }),
  '@keyframes openBounce': {
    '0%': { transform: 'translateX(-5px)' },
    '70%': { transform: 'translateX(3px)' }, // slight overshoot
    '100%': { transform: 'translateX(0)' },
  },
}}>
           <Paper elevation={3} sx={{
              flexGrow: 1,
              borderRadius: '10px', 
              backgroundColor: 'rgba(255, 255, 255, 0.97)',  
              boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',  
              overflow: 'hidden',  // Prevents child components from overflowing
              display: 'flex',
              flexDirection: 'column',
                }}>
        <Container maxWidth="false" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 0, borderColor: 'divider', mt:1 , ml: 2}}>
              <TabList onChange={handleTabChange} aria-label="course tabs" indicatorColor="secondary" textColor="secondary">
                <Tab label="Dateien" disableRipple value="1" sx={{ minWidth: 150, width: 'auto', fontWeight: (theme) => (tabValue === "1" ? 700 : 400) }} />
                <Tab label="Fragesets" disableRipple value="2" sx={{ minWidth: 150, width: 'auto',fontWeight: (theme) => (tabValue === "2" ? 700 : 400) }} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box alignItems="center" display="flex" sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
                <UploadFilesField courseId={courseId} onFileUploadSuccess={handleFileUploadSuccess} />
                <CreateQuizButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ marginLeft: 'auto' }}
                  onQuizCreated={handleQuizCreated}
                  lastFileUpload={lastFileUpload}
                  courseName={courseDetails.name}
                />
                

              </Box>
              <Grid container spacing={3} sx={{ mt: 'auto', mb: 2 }}>
                <Grid item xs={12}>
                <FileList
                files={files}
                filesError={filesError}
                currentFileId={currentFileId} 
                onFileClick={handleFileClick}
                onFileUpdated={handleFileUploadSuccess}
                courseName={courseDetails.name}
                isLoading={isLoadingFiles} 
                />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0, m: 0 }}>
              {isViewingQuestionSet ? (
                <QuestionSetDetail
                  questionSet={selectedQuestionSet}
                  onBack={handleBackToQuestionSets}
                />
              ) : (
                <>
                  <Box display="flex" justifyContent="left" alignItems="center" sx={{ flexGrow: 1 }}>
                  
                    <CreateQuizButton
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ marginRight: 'auto' }}
                      sx={{ height: '80px' }}
                      onQuizCreated={handleQuizCreated}
                     lastFileUpload={lastFileUpload}
                      courseName={courseDetails.name}
                    />
                  </Box>
                  <Grid container spacing={3} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                    <QuizList
                    questionSets={questionSets}
                    questionsError={questionsError}
                    onQuestionSetClick={handleQuestionSetClick}
                    onQuestionSetUpdated={handleQuestionSetsRefresh}
                    isQuizReady={isQuizReady}
                    courseName={courseDetails.name}
                        />
                    </Grid>
                  </Grid>
                </>
              )}
            </TabPanel>
          </TabContext>
        </Container>
        </Paper>
      </Box>
    </Box>
    {showPostPanicMessage && (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
      color: '#fff',
      fontSize: '4em',
      fontFamily: 'Nunito, sans-serif',
      opacity: messageVisible ? 1 : 0, // fades in and out
      transition: 'opacity 1s ease-in-out',
      textAlign: 'center',
      p: 3
    }}
  >
    {postPanicMessage}
  </Box>
)}
    </>
  );
}

export default CoursePage;
