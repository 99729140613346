import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FeedbackDialog from '../features/FeedbackDialog/FeedbackDialog.js';
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import { fetchUserCourses } from '../services/CourseService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { UserMenu } from './Menus/UserMenu';
import {useTheme} from '@mui/material';
import CreateCourseDialog from '../features/CreateCourseDialog/CreateCourseDialog';
import ColorMenu from '../components/Menus/ColorMenu';
import { alpha } from '@mui/material/styles';
import Icon from '../components/Icon'; 
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';


export const MainListItems = () => {
  const navigate = useNavigate();  // Create a navigation function
  const theme = useTheme(); 

    const handleMainClick = () => {
        navigate('/main');  // Assuming the main page is the root
    };

    return (
        <ListItemButton onClick={handleMainClick} sx={{
          borderRadius: '10px',
          mt: 0,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1
          },
          margin: '8px', // Add margin to give space around the buttons
          width: 'auto', // Ensure width fits content + margin
        }}>
            <ListItemIcon sx={{ minWidth: 0, mr: 3, height: 30, width: 30, ml:0.4 }}>
                <Icon iconName="courses" style={{ color: theme.palette.icon.main }}/>
            </ListItemIcon>
            <ListItemText primary="Meine Kurse"/>
        </ListItemButton>
    );
};


export const SecondaryListItems = ({ onPanicClick }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openColorMenu, setOpenColorMenu] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  const handleFeedbackClick = () => {
    setOpenFeedback(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  const handleFeedbackSubmitted = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleColorMenuClick = () => {
    setOpenColorMenu(true);
  };

  const handleCloseColorMenu = () => {
    setOpenColorMenu(false);
  };

  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <IconButton
          onClick={onPanicClick}
          sx={{     
            width: 50,
            height: 50,
          }}
        >
          <Icon iconName="panic" sx={{ fontSize: 24 }} style={{ color: theme.palette.icon.main }} />
        </IconButton>
      </Box>
     <ListItemButton onClick={handleColorMenuClick} sx={{
          borderRadius: '10px', 
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1,
          },
          margin: '8px', 
          width: 'auto', 
        }}>
        <ListItemIcon sx={{ minWidth: 0, mr: 3 }} >
          <Icon iconName="palette" style={{ color: theme.palette.icon.main }}/>
        </ListItemIcon>
        <ListItemText primary="Farbeinstellungen" />
      </ListItemButton>
      <ColorMenu open={openColorMenu} handleClose={handleCloseColorMenu} />
      <ListItemButton onClick={handleFeedbackClick} sx={{
          borderRadius: '10px', 
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1,
          },
          margin: '8px', 
          width: 'auto', 
        }}>
        <ListItemIcon sx={{ minWidth: 0, mr: 3 }} >
          <Icon iconName="feedback" style={{ color: theme.palette.icon.main }}/>
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItemButton>
      <FeedbackDialog
        open={openFeedback}
        onClose={handleFeedbackClose}
        onFeedbackSubmitted={handleFeedbackSubmitted}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <UserMenu />
    </>
  );
};



export const CourseListItems = ({ onCourseCreated }) => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme(); 

  useEffect(() => {
      fetchCourses();
  }, [onCourseCreated]); // Dependency on onCourseCreated to trigger re-fetch

  const fetchCourses = async () => {
      try {
          const fetchedCourses = await fetchUserCourses();
          setCourses(fetchedCourses);
      } catch (error) {
          console.error('Failed to fetch courses:', error);
      }
  };

  const handleCourseClick = (courseId, tab = '1') => {
      navigate(`/course/${courseId}`,{ state: { tab } });
  }

  return (
      <React.Fragment>
          {courses.map((course) => (
              <ListItemButton key={course.id} onClick={() => handleCourseClick(course.id)} sx={{
                borderRadius: '10px', 
                '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.20)',
                opacity: 1,
                },
                margin: '8px', 
                width: 'auto', 
              }}>
                <ListItemIcon sx={{ minWidth: 10, justifyContent: 'center', mr: 2,}}>
                        <Icon iconName="folder" style={{ color: theme.palette.icon.main, height: 32 }}/>
                        <Typography
                            component="span"
                            sx={{
                            position: 'absolute',
                            color: theme.palette.icon.main,
                            fontWeight: 'bold',
                            fontSize: '0.6rem',
                            top: '50%', // Centers vertically, but we'll adjust this
                            transform: 'translateY(-35%)' // Adjust to center, or move slightly down
                          }}
                          >
                          {course.name.substring(0,2).toUpperCase()}
                        </Typography>
                    </ListItemIcon>
                  <ListItemText primary={course.name} sx={{ my: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}/>
              </ListItemButton>
          ))}
      </React.Fragment>
  );
};

export const CreateCourseListItem = ({ onCourseCreated }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
      <>
        <ListItemButton onClick={handleClickOpen} sx={{
        ml: 0,
        borderRadius: '10px',
        color: alpha(theme.palette.icon.main, 0.5),
        '&:hover': {
          backgroundColor: theme.palette.primary.light, // Lighter and semi-transparent on hover
          color: theme.palette.icon.main, // Solid primary color on hover
        },
        margin: '8px',
        width: 'auto',
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the left
        alignItems: 'center', // Ensures vertical centering
      }}>
        <ListItemIcon sx={{ minWidth: 40, color: 'inherit' , ml: 0.45}}> 
          <Icon iconName="plusCircle" />
        </ListItemIcon>
        <ListItemText primary="Neuer Kurs" sx={{ color: 'inherit', ml: 0.6
         }} /> 
      </ListItemButton>
      <CreateCourseDialog open={open} onClose={handleClose} onCourseCreated={onCourseCreated}/>
    </>
  );
};
