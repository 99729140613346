import React from 'react';
import { useTheme } from '@mui/material/styles';

const Icon = ({ iconName, ...props }) => {
  const theme = useTheme();
  const IconComponent = theme.icons[iconName];

  if (!IconComponent) {
    console.warn(`Icon "${iconName}" not found in theme.icons`);
    return null;
  }

  return <IconComponent {...props} />;
};

export default Icon;
