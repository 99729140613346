import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
  Box, Typography, Paper, TextField, Checkbox, Divider, Snackbar, Alert
} from '@mui/material';
import { fetchFilesByCourse } from '../../services/FileService';
import ENDPOINTS from '../../utils/apiConfig';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FileList from '../../components/Lists/FileList';
import { fetchFileDetails } from '../../services/FileService';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/Icon';


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateQuizDialog = ({ open, onClose, courseId, onQuizCreated, lastUploadTimestamp, courseName }) => {
  
  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [selectedPages, setSelectedPages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [filePages, setFilePages] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [lastSelectedFileId, setLastSelectedFileId] = useState(null); // Added line
  const [isFileReady, setIsFileReady] = useState({});
  const navigate = useNavigate();
  

  // Data fetching and state updates 

  useEffect(() => {
    if (!open) {
      setQuizName('');
      setSelectedFileIds([]);
      setSelectedPages({});
      setIsFileReady({});
      setFilePages({});
      setErrorMessage('');
      setSuccessMessage('');
      setCurrentPage(1);
      setLastSelectedIndex(null);
      setLastSelectedFileId(null); // Updated line
    }
  }, [open]);

  // Removed or updated the useEffect that logs selectedFileId
  // If not needed, you can remove it entirely

  // useEffect(() => {
  //   console.log("Selected File IDs:", selectedFileIds);
  // }, [selectedFileIds]);
  
  useEffect(() => {
    fetchFilesByCourse(courseId)
      .then(setFiles)
      .catch(error => {
        console.error('Failed to fetch files:', error);
        setFilesError('Failed to load file data');
      });
  }, [courseId, lastUploadTimestamp]); 

  useEffect(() => {
    console.log("File readiness status changed to:", isFileReady);
  
  }, [isFileReady]);
  
  const updateFileInState = (updatedFile) => {
    setFiles(prevFiles => {
        const newFiles = prevFiles.map(file => file.id === updatedFile.id ? updatedFile : file);
        console.log('Updated files:', newFiles); 
        return newFiles;
    });
  };

  // Handlers for file interaction

  const handleFileClick = async (file) => {
    if (selectedFileIds.includes(file.id)) {
      // Deselect file
      setSelectedFileIds(selectedFileIds.filter((id) => id !== file.id));
      const { [file.id]: _, ...rest } = selectedPages;
      setSelectedPages(rest); // Remove pages of deselected file
      const { [file.id]: __, ...restReady } = isFileReady;
      setIsFileReady(restReady); // Remove readiness status
      const { [file.id]: ___, ...restPages } = filePages;
      setFilePages(restPages); // Remove pages of deselected file
    } else {
      // Select file
      setSelectedFileIds([...selectedFileIds, file.id]);
      setSelectedPages({ ...selectedPages, [file.id]: [] }); // Initialize selected pages for this file
      checkFileStatusOnce(file.id);
    }
  };

  const checkFileStatusOnce = async (fileId) => {
    if (!fileId) return;

    try {
      const fileData = await fetchFileDetails(fileId);
      console.log("File data received:", fileData);

      const ready = fileData.text_status === 'completed' && fileData.images_status === 'completed';

      setIsFileReady(prevState => ({
        ...prevState,
        [fileId]: ready,
      }));

      if (ready) {
        console.log("File is ready for use:", fileId);
        fetchFilePages(fileId); // Fetch pages only if file is ready
      } else {
        console.log("File is not ready:", fileId, "Status:", fileData.text_status, fileData.images_status);
      }
    } catch (error) {
      console.error('Error fetching file status:', error);
      setIsFileReady(prevState => ({
        ...prevState,
        [fileId]: false,
      }));
      console.log("Error during file status check:", fileId, error);
    }
  };

  const fetchFilePages = async (fileId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(ENDPOINTS.GET_FILE_PAGE_SMALL_IMAGES(fileId), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setFilePages(prevPages => ({
          ...prevPages,
          [fileId]: data,
        }));
      } else {
        console.error(`Failed to fetch pages for file ${fileId}:`, await response.text());
      }
    } catch (error) {
      console.error(`Error fetching pages for file ${fileId}:`, error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handlePreviousPage = () => {
    setCurrentPage(1);
  };

  const handlePageSelection = (fileId, index, event) => {
    setSelectedPages(prevSelectedPages => {
      const pagesForFile = prevSelectedPages[fileId] || [];
      let updatedPages;
      if (event.shiftKey && lastSelectedIndex !== null && lastSelectedFileId === fileId) {
        const start = Math.min(lastSelectedIndex, index);
        const end = Math.max(lastSelectedIndex, index);
        const range = Array.from({ length: end - start + 1 }, (_, i) => start + i);
        updatedPages = Array.from(new Set([...pagesForFile, ...range]));
      } else if (event.metaKey || event.ctrlKey) {
        updatedPages = pagesForFile.includes(index)
          ? pagesForFile.filter(page => page !== index)
          : [...pagesForFile, index];
        setLastSelectedIndex(index);
        setLastSelectedFileId(fileId);
      } else {
        updatedPages = pagesForFile.includes(index)
          ? pagesForFile.filter(page => page !== index)
          : [...pagesForFile, index];
        setLastSelectedIndex(index);
        setLastSelectedFileId(fileId);
      }
      return { ...prevSelectedPages, [fileId]: updatedPages };
    });
  };

  const handleSelectAll = () => {
    const allSelected = Object.values(selectedPages).every(pages => pages.length > 0);

    if (allSelected) {
      // Deselect all
      setSelectedPages({});
    } else {
      // Select all pages for all files
      const allPages = {};
      for (const [fileId, pages] of Object.entries(filePages)) {
        allPages[fileId] = pages.map((_, index) => index);
      }
      setSelectedPages(allPages);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
    setSelectedImage(null);
  };

  // Handler for submitting the quiz
  
  const handleSubmit = async () => {
    const data = {
      action: 'new_set',
      course_id: courseId,
      name: quizName,
      files: Object.entries(selectedPages).map(([fileId, pages]) => ({
        file_id: parseInt(fileId, 10),
        pages: pages.map(index => index + 1),
      })),
      question_type: 'SA',
    };
  
    try {
      const response = await fetch(ENDPOINTS.CREATE_QUESTIONSET, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.detail === "A QuestionSet with this name already exists in the specified course.") {
          setErrorMessage('Ein Set mit diesem Namen existiert bereits.');
        } else {
          console.error('Failed to create quiz:', errorData);
          setErrorMessage('Fehler beim Erstellen des Fragesets. Bitte versuche es erneut.');
        }
        return;
      }
  
      const responseData = await response.json();
      console.log('Quiz Created:', responseData);
      setSuccessMessage('Frageset wird erstellt!');
  
      if (responseData && responseData.creation_request && responseData.creation_request.id) {
        console.log('Calling onQuizCreated with ID:', responseData.creation_request.id);
        onQuizCreated(responseData.creation_request.id); 
      }

      setTimeout(() => {
        console.log('Navigating to course page:', courseId);
        navigate(`/course/${courseId}?tab=2`);
        onClose();
      }, 3000);
  
    } catch (error) {
      console.error('Error creating quiz:', error);
      setErrorMessage('Netzwerkfehler. Bitte versuche es erneut.');
    }    
  };
  

  const renderCheckbox = (file) => (
    <Checkbox
      checked={selectedFileIds.includes(file.id)}
      onChange={() => handleFileClick(file)}
      disabled={!isFileReady[file.id]}
      inputProps={{ 'aria-label': 'Select file' }}
      color="secondary"
    />
  );

  // UI for the dialog

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
      {/* Page 1 */}
      {currentPage === 1 ? (
        <>
          <DialogContent sx={{ ml: 3, mr: 6 }}>
            <DialogTitle sx={{ pl: 0 }}>Frageseterstellung</DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ mb: 2 }}>
                <DialogTitle sx={{ pl: 0 }}>Name</DialogTitle>
                <DialogContentText sx={{ pl: 0 }}>
                  Bitte gib einen Namen für dein Frageset ein.
                </DialogContentText>
                <TextField
                  autoFocus
                  id="courseName"
                  label="Fragensetname"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={quizName}
                  onChange={(e) => setQuizName(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <DialogTitle sx={{ pl: 0 }}>Datei</DialogTitle>
                <DialogContentText sx={{ pl: 0, mb: 2 }}>
                  Bitte wähle die Dateien aus, auf deren Basis dein Frageset erstellt werden soll.
                </DialogContentText>
                <FileList
                  files={files}
                  filesError={filesError}
                  currentFileIds={selectedFileIds} // Pass the array of selected file IDs
                  onFileClick={handleFileClick}
                  courseName={courseName}
                  fileControlComponent={(file) => renderCheckbox(file)} 
                  onFileUpdated={updateFileInState}
                  style={{ height: '400px', overflowY: 'auto' }}
                  processingMessage="Deine Datei ist noch nicht bereit für eine Quizerstellung. Bitte warte noch kurz."
                  isFileReady={isFileReady}
                  showEditAndDeleteButtons={false}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={handleNextPage}
              color="primary"
              disabled={
                !quizName ||
                selectedFileIds.length === 0 ||
                !selectedFileIds.every((id) => isFileReady[id])
              }
            >
              Weiter
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          {/* Page 2 */}
          <DialogContent sx={{ ml: 3, mr: 6 }}>
            <DialogTitle sx={{ pl: 0 }}>Frageseterstellung</DialogTitle>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <Box
                sx={{
                  width: '20%',
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Name
                </Typography>
                <Typography color="grey" sx={{ mb: 2 }}>
                  {quizName}
                </Typography>
                <Typography sx={{ mb: 2 }} variant="h6">
                  Dateien
                </Typography>
                <Typography color="grey" sx={{ wordBreak: 'break-word' }}>
                  {selectedFileIds
                    .map((fileId) => files.find((file) => file.id === fileId)?.file_name)
                    .join(', ')}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: 'auto', alignSelf: 'center' }}
              />
              <Box sx={{ flex: 1, padding: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">Seiten auswählen</Typography>
                  <Button onClick={handleSelectAll} color="primary" variant="outlined">
                    Alle auswählen
                  </Button>
                </Box>
                <DialogContentText sx={{ pl: 0, mb: 2 }}>
                  Bitte wähle alle Seiten deiner Dateien aus, die für die Quizerstellung
                  berücksichtigt werden sollen.
                </DialogContentText>
                <Paper
                  elevation={4}
                  sx={{ p: 2, height: '545px', overflowY: 'auto' }}
                >
                  {Object.entries(filePages).map(([fileId, pages]) => (
                    <Box key={fileId} sx={{ mb: 2 }}>
                      <Typography sx={{ mb: 1 }}>
                        {' '}
                        {files.find((file) => file.id === parseInt(fileId, 10))
                          ?.file_name}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {pages.map((page, index) => (
                          <Box
                            key={`${fileId}-${index}`}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              width: '18%',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                border: selectedPages[fileId]?.includes(index)
                                  ? '2px solid black'
                                  : '1px solid grey',
                                borderRadius: '4px',
                                backgroundColor: selectedPages[fileId]?.includes(index)
                                  ? 'grey.300'
                                  : 'inherit',
                                p: 1,
                                cursor: 'pointer',
                              }}
                              onClick={(e) => handlePageSelection(fileId, index, e)}
                            >
                              <Box
                                component="img"
                                src={`${BASE_URL}${page.image_url}`}
                                alt={`Page ${page.page_number}`}
                                sx={{
                                  width: '100%',
                                  borderRadius: '4px',
                                }}
                              />
                            </Box>
                            <Button
                              size="small"
                              sx={{
                                mt: 1,
                                display: 'block',
                                mx: 'auto',
                                textTransform: 'none',
                                fontSize: '0.8rem',
                                width: '80%',
                                color: 'grey.700',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleImageClick(`${BASE_URL}${page.image_url}`);
                              }}
                            >
                              Details
                            </Button>
                          </Box>
                        ))}
                      </Box>
                      <Divider sx={{ my: 1 }} />
                    </Box>
                  ))}
                </Paper>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
            <Button onClick={handlePreviousPage} color="primary">
              Zurück
            </Button>
            <Button
    onClick={handleSubmit}
    color="secondary"
    variant="contained"
    disabled={Object.values(selectedPages).every(pages => pages.length === 0)}
    startIcon={successMessage ? <Icon iconName="checkCircle" /> : null}
    sx={{
      // If there's an error message, apply a wiggle animation
      ...(errorMessage && {
        animation: 'wiggle 0.8s ease-in-out',
      }),
      '@keyframes wiggle': {
        '0%, 100%': { transform: 'translateX(0)' },
        '10%, 30%, 50%, 70%, 90%': { transform: 'translateX(-5px)' },
        '20%, 40%, 60%, 80%': { transform: 'translateX(5px)' },
      },
    }}
  >
    {successMessage ? successMessage : errorMessage ? errorMessage : "Set Erstellen"}
  </Button>
          </DialogActions>
        </>
      )}
      {/* Image Dialog */}
      <Dialog open={isImageOpen} onClose={handleCloseImage} maxWidth="lg">
        <IconButton
          onClick={handleCloseImage}
          sx={{
            marginLeft: 'auto',
            width: 30,
            height: 30,
            padding: 0,
            marginTop: '20px',
            marginRight: '20px',
          }}
        >
          <Icon iconName="cross" />
        </IconButton>
        <DialogContent>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Selected Page"
              sx={{
                width: '100%',
                borderRadius: '4px',
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default CreateQuizDialog;
