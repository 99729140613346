import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CreateCourseDialog from '../../features/CreateCourseDialog/CreateCourseDialog';
import Icon from '../../components/Icon'; // The reusable Icon component

export default function CreateCourseButton({ onCourseCreated }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        variant="contained" 
        size="large" 
        color="secondary" 
        onClick={handleClickOpen} 
        endIcon={<Icon iconName="addFolder" />} 
      >
        Neuen Kurs erstellen
      </Button>
      <CreateCourseDialog open={open} onClose={handleClose} onCourseCreated={onCourseCreated}/>
    </div>
  );
}
