import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Box, Container, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import {useTheme} from '@mui/material';
import SideDrawer from '../../components/Drawer';
import { fetchUserCourses } from "../../services/CourseService";
import { fetchUserDetails } from '../../services/UserService';
import CreateCourseButton from '../../components/Button/CreateCourseButton';
import EditCourseButton from '../../components/Button/EditCourseButton';
import DeleteCourseButton from '../../components/Button/DeleteCourseButton';
import { useSidebar } from '../../contexts/SidebarContext';
import Icon from '../../components/Icon';

const drawerWidth = 240;

export default function MainPage() {
  const { isOpen, toggleDrawer } = useSidebar();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  // Panic-related states
  const [panicMode, setPanicMode] = useState(false);
  const [showPostPanicMessage, setShowPostPanicMessage] = useState(false);
  const [postPanicMessage, setPostPanicMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  const originalGradient = theme.palette.gradient.background;
  const postPanicMessages = [
    "Atme durch und leg wieder los!",
    "Puh, kurze Pause und weiter gehts.",
    "Alles gut, weiter geht's!",
    "Tief durchatmen, du schaffst das!",
    "Kurzer Reset - jetzt mit frischer Energie!"
  ];

  let hueInterval = null;
  let currentHue = 0;
  let twistInterval = null;

  useEffect(() => {
    async function fetchData() {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);
        const fetchedCourses = await fetchUserCourses();
        setCourses(fetchedCourses);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleCourseCreated = async () => {
    setIsLoading(true);
    const fetchedCourses = await fetchUserCourses();
    setCourses(fetchedCourses);
    setIsLoading(false);
  };

  // Panic logic similar to CoursePage
  const handlePanicClick = () => {
    setPanicMode(true);
    startPanicEffects();
    setTimeout(() => {
      stopPanicEffects();
      setPanicMode(false);
    }, 4000); // panic lasts 4s
  };

  const startPanicEffects = () => {
    document.body.classList.add('panic-shake');
    startColorCycle();
    startRandomTwists();
  };

  const stopPanicEffects = () => {
    document.body.classList.remove('panic-shake');
    stopColorCycle();
    stopRandomTwists();

    // Restore background
    document.documentElement.style.setProperty('--panic-bg', originalGradient);

    // Show post-panic message
    const randomMsg = postPanicMessages[Math.floor(Math.random() * postPanicMessages.length)];
    setPostPanicMessage(randomMsg);
    setShowPostPanicMessage(true);
    setTimeout(() => {
      setMessageVisible(true);
    }, 50);

    setTimeout(() => {
      setMessageVisible(false);
    }, 3000);

    setTimeout(() => {
      setShowPostPanicMessage(false);
    }, 4000);
  };

  const startColorCycle = () => {
    currentHue = 0;
    hueInterval = setInterval(() => {
      currentHue = (currentHue + 20) % 360;
      document.body.style.filter = `hue-rotate(${currentHue}deg)`;
    }, 50);
  };

  const stopColorCycle = () => {
    if (hueInterval) {
      clearInterval(hueInterval);
      hueInterval = null;
    }
    document.body.style.filter = '';
  };

  const startRandomTwists = () => {
    const selectors = ['.paper-content', 'button', 'p', 'h1', 'h2', 'h3'];
    twistInterval = setInterval(() => {
      selectors.forEach((sel) => {
        const elements = document.querySelectorAll(sel);
        elements.forEach(elem => {
          if (Math.random() < 0.5) {
            const rotate = Math.floor(Math.random() * 40) - 20;
            const scale = 1 + (Math.random() * 0.5 - 0.25);
            elem.style.transition = 'transform 0.2s ease';
            elem.style.transform = `rotate(${rotate}deg) scale(${scale})`;
          } else {
            elem.style.transform = '';
          }
        });
      });
    }, 300);
  };

  const stopRandomTwists = () => {
    if (twistInterval) {
      clearInterval(twistInterval);
      twistInterval = null;
    }
    const allElements = document.querySelectorAll('.paper-content, button, p, h1, h2, h3');
    allElements.forEach(elem => {
      elem.style.transform = '';
      elem.style.transition = '';
    });
  };

  return (
    <>
    <Box sx={{ background: panicMode ? 'var(--panic-bg)' : theme.palette.gradient.background, minHeight: '100vh', width: '100%', overscrollBehavior: 'contain'  }}>
      <CssBaseline />
      <SideDrawer open={isOpen} toggleDrawer={toggleDrawer} onCourseCreated={handleCourseCreated} onPanicClick={handlePanicClick} />
      <Box component="main" sx={{
        flexGrow: 1,
        p: 1,
        width: isOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 72px)`,
        ml: isOpen ? `${drawerWidth}px` : `72px`,
        transform: isOpen ? 'none' : 'translateX(-5px)',
        transition: `
          width 500ms cubic-bezier(0.2, 1, 0.2, 1),
          margin 500ms cubic-bezier(0.2, 1, 0.2, 1)
        `,
        backgroundColor: "transparent",
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
        ...(isOpen && {
          animation: 'openBounce 500ms cubic-bezier(0.2, 1, 0.2, 1) forwards'
        }),
        '@keyframes openBounce': {
          '0%': { transform: 'translateX(-5px)' },
          '70%': { transform: 'translateX(3px)' },
          '100%': { transform: 'translateX(0)' },
        },
      }}>
        <Paper elevation={3} sx={{
          flexGrow: 1,
          borderRadius: '10px', 
          backgroundColor: 'rgba(255, 255, 255, 0.97)',  
          boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',  
          overflow: 'hidden',  
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Container maxWidth="false" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}>
            <Box alignItems="center" display="flex" sx={{ flexGrow: 1, paddingTop: 8}}>
              <CreateCourseButton onCourseCreated={handleCourseCreated} color="primary"/>
            </Box>
            <Grid container spacing={3} sx={{ mt: 'auto', mb: 2 }}>
              <Grid item xs={12}>
                <Paper elevation={4} sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '600px', width: "100%", borderRadius: "20px"}}>
                  <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 1, pb: 2, backgroundColor: 'background.paper', width: '100%' }}>
                    Meine Kurse
                  </Typography>
                  {isLoading ? (
                    <CircularProgress color="secondary" style={{ margin: 'auto' }} />
                  ) : courses.length > 0 ? (
                    courses.map((course) => (
                      <Box
                        key={course.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 2,
                          p: 2,
                          width: '100%',
                          '&:hover': {
                            backgroundColor: 'grey.200',
                            cursor: 'pointer'
                          }
                        }}
                        onClick={() => handleCourseClick(course.id)}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, overflow: 'hidden' }}>
                          <Icon iconName="folder" style={{ width: '35px', height: '35px' }} />
                          <span style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}>
                            {course.name}
                          </span>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }} onClick={(e) => e.stopPropagation()}>
                          <EditCourseButton courseId={course.id} onCourseUpdated={handleCourseCreated} />
                          <DeleteCourseButton courseId={course.id} onCourseUpdated={handleCourseCreated} />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
                      Du hast noch keine Kurse erstellt. Beginne damit einen neuen Kurs zu erstellen.
                    </Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Box>

      {showPostPanicMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            color: '#fff',
            fontSize: '4em',
            fontFamily: 'Nunito, sans-serif',
            opacity: messageVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            textAlign: 'center',
            p: 3
          }}
        >
          {postPanicMessage}
        </Box>
      )}
    </Box>
    </>
  );
}
