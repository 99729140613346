import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, ListItemButton, IconButton, Typography, Divider } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { fetchUserDetails } from '../../services/UserService';
import { useSidebar } from '../../contexts/SidebarContext';
import {useTheme} from '@mui/material';
import ProfileMenu from './ProfileMenu';  // Adjust path as needed
import Icon from '../../components/Icon'; 

export const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { isOpen, toggleDrawer } = useSidebar();
    const { logout } = useAuth();
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();
    const [openProfile, setOpenProfile] = useState(false);  // State to control SettingsMenu visibility
    const theme = useTheme(); 


    useEffect(() => {
      const loadUserDetails = async () => {
        try {
            const userDetails = await fetchUserDetails();
            setUserEmail(userDetails.email);
        } catch (error) {
            console.error('Failed to load user details:', error);
        }
      };
      loadUserDetails();
    }, []);

    const handleClick = (event) => {
        if (!isOpen) {
            toggleDrawer();
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
        navigate('/login');
    };

    const handleOpenProfile = () => {
        setOpenProfile(true);  // Open the SettingsMenu dialog
        handleClose();  // Close the user menu
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);  // Close the SettingsMenu dialog
    };
    

    return (
        <>
            
            <ListItemButton onClick={handleClick} sx={{
          borderRadius: '10px', // Rounded edges
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.20)',
              opacity: 1,
            },
           
          margin: '8px', 
          width: 'auto', 
        }}>
                <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
                    <Icon iconName="user" style={{ color: theme.palette.icon.main }}/>
                </ListItemIcon>
                <ListItemText primary="Nutzer" />
            </ListItemButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{ transform: 'translateY(-10px)' }}
                PaperProps={{ style: { minWidth: '210px', width: '210px' } }}
            >
                <MenuItem onClick={handleClose}>
                    <Typography variant="body2" sx={{ color: 'grey' }}>
                        {userEmail}
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenProfile}>
                    <ListItemIcon>
                        <Icon iconName="profile" />
                    </ListItemIcon>
                    Mein Profil
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Icon iconName="logout" />
                    </ListItemIcon>
                    Log out
                </MenuItem>
            </Menu>
            <ProfileMenu open={openProfile} handleClose={handleCloseProfile} />
        
        </>
    );
};
