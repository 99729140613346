import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Paper,
  IconButton, 
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import { ReactComponent as UnknownSmiley } from '../../assets/UnknownSmiley.svg'; 
import Icon from '../../components/Icon'; 


const MenuContainer = styled(Paper)(({ theme, isPinned }) => ({
  width: '267px',
  height: isPinned ? '230px' : '58px', // Adjust height based on pinned state
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  '&:hover': isPinned
    ? {}
    : {
        height: '230px',
      },
}));

const CollapsedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const StatisticsMenu = ({ questions, pinnedStateKey }) => {
  const theme = useTheme();

  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem(pinnedStateKey);
    return savedPinnedState ? JSON.parse(savedPinnedState) : false;
  });

  const togglePinned = () => {
    setIsPinned((prev) => {
      const newState = !prev;
      localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
      return newState;
    });
  };

  const [statusCounts, setStatusCounts] = useState({
    sad: 0,
    okay: 0,
    happy: 0,
    none: 0,
  });

  useEffect(() => {
    const counts = questions.reduce((acc, question) => {
      const status = question.flashcard_status || 'none';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, { sad: 0, okay: 0, happy: 0, none: 0 });

    setStatusCounts(counts);
  }, [questions]);

  const [rotations, setRotations] = useState({
    sad: [],
    okay: [],
    happy: [],
    none: [],
  });

  const prevStatusCounts = useRef(statusCounts);

  // Initialize rotations on mount
  useEffect(() => {
    setRotations({
      sad: Array.from({ length: statusCounts.sad }, () => (Math.random() - 0.5) * 10),
      okay: Array.from({ length: statusCounts.okay }, () => (Math.random() - 0.5) * 10),
      happy: Array.from({ length: statusCounts.happy }, () => (Math.random() - 0.5) * 10),
      none: Array.from({ length: statusCounts.none }, () => (Math.random() - 0.5) * 10),
    });
    prevStatusCounts.current = statusCounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once on mount

  useEffect(() => {
    ['sad', 'okay', 'happy', 'none'].forEach((status) => {
      const prevCount = prevStatusCounts.current[status] || 0;
      const currentCount = statusCounts[status] || 0;

      if (currentCount > prevCount) {
        const numToAdd = currentCount - prevCount;
        setRotations((prevRot) => ({
          ...prevRot,
          [status]: [
            ...prevRot[status],
            ...Array.from({ length: numToAdd }, () => (Math.random() - 0.5) * 10),
          ],
        }));
      } else if (currentCount < prevCount) {
        const numToRemove = prevCount - currentCount;
        setRotations((prevRot) => ({
          ...prevRot,
          [status]: prevRot[status].slice(0, currentCount),
        }));
      }
    });

    prevStatusCounts.current = statusCounts;
  }, [statusCounts]);

  const renderStack = (rotationsArray) => {
    const maxStackHeight = 80; 
    const lineHeight = 1.5;
    const lineWidth = 20;
    const lineSpacing = 2;

    const count = rotationsArray.length;
    let stackHeight = count * (lineHeight + lineSpacing);
    let adjustedLineHeight = lineHeight;

    if (stackHeight > maxStackHeight) {
      adjustedLineHeight = (maxStackHeight - count * lineSpacing) / count;
      stackHeight = maxStackHeight;
    }

    const lines = rotationsArray.map((rotation, index) => (
      <Box
        key={index}
        sx={{
          width: `${lineWidth}px`,
          height: `${adjustedLineHeight}px`,
          backgroundColor: theme.palette.primary.main,
          marginBottom: `${lineSpacing}px`,
          transform: `rotate(${rotation}deg)`,
        }}
      />
    ));

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end', 
          height: `${maxStackHeight}px`,
          overflow: 'hidden',
          marginBottom: '5px',
        }}
      >
        {lines}
      </Box>
    );
  };

  return (
    <MenuContainer elevation={3} isPinned={isPinned}>
      {/* Collapsed Content with custom statistic icon */}
      <CollapsedContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
            mt: 2,
          }}
        >
          <Icon 
            iconName="statistic"
            style={{ color: theme.palette.primary.main }}
            sx={{
              width: 22,
              height: 22,
            }}
          />
        </Box>
      </CollapsedContent>
      {/* Expanded Content */}
      <ExpandedContent>
        {/* Pin Icon at Top-Right Corner */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 1,
          }}
        >
          <IconButton
            onClick={togglePinned}
            sx={{
              // Color based on pinned state
              color: isPinned ? theme.palette.primary.main : theme.palette.grey[500],
              height: 35,
              width: 35,
            }}
            aria-label={isPinned ? 'Unpin menu' : 'Pin menu'}
          >
            {/* Custom pin icon inheriting color from parent */}
            <Icon iconName="pin" sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>

        {/* Display stacks */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexGrow: 1,
          }}
        >
          {/* Sad */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.sad)}
            <SadSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          {/* Neutral */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.okay)}
            <NeutralSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          {/* Happy */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.happy)}
            <HappySmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          {/* None - Replace HelpOutlineIcon with UnknownSmiley */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.none)}
            <UnknownSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
        </Box>
      </ExpandedContent>
    </MenuContainer>
  );
};

export default StatisticsMenu;


