import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const ShuffleButton = ({ isShuffled, toggleShuffle }) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={() => {
        console.log("Shuffle button clicked");  // Log when button is clicked
        toggleShuffle();
      }}
      color="inherit" // Control color via sx
      sx={{
        mx: 1, // Adjust as needed
        width: 35, 
        height: 35,
        borderRadius: '50%',
        backgroundColor: 'transparent', // No background
        '&:hover .shuffle-icon': {
          color: isShuffled
            ? theme.palette.primary.dark // Darker primary on hover when active
            : grey[700], // Darker grey on hover when inactive
        },
      }}
    >
      <Icon
        iconName="shuffle"
        className="shuffle-icon"
        style={{ color: isShuffled ? theme.palette.primary.main : grey[500] }} 
        sx={{
          fontSize: '30px', // Smaller icon
          transition: 'color 0.3s',
        }}
      />
    </IconButton>
  );
};

export default ShuffleButton;

