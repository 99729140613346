import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Paper, Box, Button } from '@mui/material';
import { fetchQuestionsByQuestionSet } from '../../services/GetQuestionsService';
import { fetchQuestionSetById } from '../../services/GetQuestionSetService';
import './LearningMode.css';
import { useTheme } from '@mui/material';
import QuizMenu from '../../components/Menus/QuizMenu';
import StatisticsMenu from '../../components/Menus/StatisticsMenu';
import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import FlashcardRatingButtons, {
  SadSmileyButton,
  NeutralSmileyButton,
  HappySmileyButton,
} from '../../components/Button/FlashcardRatingButtons';
import Icon from '../../components/Icon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LearningMode = ({ open, onClose, questionSetId }) => {

  const checkboxStateKey = `learningModeCheckboxState-${questionSetId}`;
  const sliderValueKey = `learningModeSliderValue-${questionSetId}`;

  const theme = useTheme();

  // use state hooks 
  const [questions, setQuestions] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [unshuffledQuestions, setUnshuffledQuestions] = useState([]);
  const [questionSetName, setQuestionSetName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`learningModeIndex-${questionSetId}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [displayContent, setDisplayContent] = useState('');
  const [contentType, setContentType] = useState('');
  const [isPoemVisible, setIsPoemVisible] = useState(false);
  const [isShuffled, setIsShuffled] = useState(true); 
  const [isWiggling, setIsWiggling] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [totalAvailableQuestions, setTotalAvailableQuestions] = useState(undefined);


  // Initialize filter settings from localStorage
  const [checkboxState, setCheckboxState] = useState(() => {
    const savedCheckboxState = localStorage.getItem(checkboxStateKey);
    return savedCheckboxState
      ? JSON.parse(savedCheckboxState)
      : {
          sad: true,
          okay: true,
          happy: true,
          none: true,
        };
  });
  
  const [sliderValue, setSliderValue] = useState(() => {
    const savedSliderValue = localStorage.getItem(sliderValueKey);
    if (savedSliderValue !== null) {
      const parsedValue = parseInt(savedSliderValue, 10);
      // Check if parsedValue is a valid number and greater than 0
      if (!isNaN(parsedValue) && parsedValue > 0) {
        return parsedValue;
      }
    }
    // If not valid, default to null for now
    return null;
  });
  


  useEffect(() => {
    // If we have totalAvailableQuestions and no valid sliderValue, default to totalAvailableQuestions
    if (totalAvailableQuestions !== undefined && (sliderValue === null || sliderValue <= 0)) {
      console.log('Setting sliderValue to totalAvailableQuestions:', totalAvailableQuestions);
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);
  



  const currentQuestion = (currentQuestions.length > 0 && currentIndex < currentQuestions.length) ? currentQuestions[currentIndex] : null;

    // Save filter settings to localStorage
    useEffect(() => {
      localStorage.setItem(checkboxStateKey, JSON.stringify(checkboxState));
    }, [checkboxState]);

    

    // Fetch questions on component mount
    useEffect(() => {
      const fetchData = async () => {
        try {
          const questionData = await fetchQuestionsByQuestionSet(questionSetId);
          setQuestions(questionData);
          const questionSetData = await fetchQuestionSetById(questionSetId);
          setQuestionSetName(questionSetData.name);
    
          // Call applyFilters after setting questions
          if (questionData.length > 0 && sliderValue !== null) {
            console.log('Calling applyFilters after fetching questions');
            applyFilters();
          }
        } catch (error) {
          console.error('Failed to fetch question set details:', error);
        }
      };
    
      if (open) {
        fetchData();
      }
    }, [open, questionSetId]);
    

// Event handler to pass checkbox change 
const handleCheckboxChange = (updatedCheckboxState) => {
  setCheckboxState(updatedCheckboxState);

  // Recalculate totalAvailableQuestions based on the updated checkboxState
  let selectedStatuses = Object.keys(updatedCheckboxState).filter(
    (key) => updatedCheckboxState[key]
  );

  // If no statuses are selected, default to all
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  const filteredQuestions = filterQuestionsByStatus(questions, selectedStatuses);
  const totalAvailable = filteredQuestions.length;

  setTotalAvailableQuestions(totalAvailable); // Update state

  setSliderValue(totalAvailable > 0 ? totalAvailable : null);
};


// Apply filters when questions are fetched
useEffect(() => {
  if (questions.length > 0 && sliderValue !== undefined) {
    console.log('Calling applyFilters');
    applyFilters();
  }
}, [sliderValue, checkboxState]); // Removed 'questions' from dependencies


  // Handle shuffling
  const shuffleQuestions = (questionsToShuffle) => {
    let shuffled = [...questionsToShuffle];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    if (totalAvailableQuestions !== undefined && sliderValue === null) {
      console.log('Setting sliderValue to totalAvailableQuestions:', totalAvailableQuestions);
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);
  

  const toggleShuffle = () => {
    setIsWiggling(true);
    setTimeout(() => {
      setIsWiggling(false);
    }, 600);

    if (!isShuffled) {
      setUnshuffledQuestions(currentQuestions);
      const shuffled = shuffleQuestions(currentQuestions);
      setCurrentQuestions(shuffled);
      setCurrentIndex(0);
      setIsShuffled(true);
    } else {
      setCurrentQuestions(unshuffledQuestions);
      setCurrentIndex(0);
      setIsShuffled(false);
    }
  };

// Filter questions based on selected statuses
const filterQuestionsByStatus = (questionsToFilter, statuses) => {
  return questionsToFilter.filter((question) => {
    if (!question) return false;
    const status = question.flashcard_status || 'none';
    return statuses.includes(status);
  });
};

 // Ensure sliderValue does not exceed totalAvailableQuestions
  useEffect(() => {
    if (sliderValue != null && sliderValue > totalAvailableQuestions) {
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);



  

// Apply filters
const applyFilters = () => {
  // Calculate selected statuses
  let selectedStatuses = Object.keys(checkboxState).filter(
    (key) => checkboxState[key]
  );

  // If no statuses are selected, default to all
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  // Filter questions based on selected statuses
  const filteredQuestions = filterQuestionsByStatus(questions, selectedStatuses);

  const totalAvailable = filteredQuestions.length;
  setTotalAvailableQuestions(totalAvailable); // Update the state variable

  const safeSliderValue = (sliderValue === null || sliderValue <= 0)
  ? totalAvailable
  : sliderValue;

   const adjustedSliderValue = Math.min(safeSliderValue, totalAvailable);

  // Limit the questions based on adjusted sliderValue
  const limitedQuestions = filteredQuestions.slice(0, adjustedSliderValue);

  // Update state
  if (isShuffled) {
    const shuffledQuestions = shuffleQuestions(limitedQuestions);
    setCurrentQuestions(shuffledQuestions);
  } else {
    setCurrentQuestions(limitedQuestions);
  }
  setUnshuffledQuestions(limitedQuestions);

  // Adjust currentIndex if necessary
  if (limitedQuestions.length === 0) {
    setCurrentIndex(0);
  } else if (currentIndex >= limitedQuestions.length) {
    setCurrentIndex(0);
  }

  setIsMenuOpen(false);
};

useEffect(() => {
  if (questions.length === 0) return; 
  let selectedStatuses = Object.keys(checkboxState).filter(
    (key) => checkboxState[key]
  );

  // If no statuses are selected, default to all
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  const filteredQuestions = filterQuestionsByStatus(questions, selectedStatuses);
  const totalAvailable = filteredQuestions.length;

  setTotalAvailableQuestions(totalAvailable); // Update state

  if (sliderValue != null && sliderValue > totalAvailable) {
    setSliderValue(totalAvailable);
  }
}, [questions]);

  // Update flashcard status
  const updateFlashcardStatus = (newStatus) => {
    if (!currentQuestion) return;
  
    const updatedQuestions = questions.map((q) =>
      q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
    );
    setQuestions(updatedQuestions);
  
    const updatedCurrentQuestions = currentQuestions.map((q) =>
      q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
    );
    setCurrentQuestions(updatedCurrentQuestions);
  
    if (isShuffled) {
      const updatedUnshuffledQuestions = unshuffledQuestions.map((q) =>
        q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
      );
      setUnshuffledQuestions(updatedUnshuffledQuestions);
    }
  };
  

// Save checkboxState to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem(checkboxStateKey, JSON.stringify(checkboxState));
}, [checkboxState]);

// Save sliderValue to localStorage whenever it changes
useEffect(() => {
  if (sliderValue !== null && sliderValue !== undefined && !isNaN(sliderValue) && sliderValue > 0) {
    localStorage.setItem(sliderValueKey, String(sliderValue));
  } else {
    localStorage.removeItem(sliderValueKey);
  }
}, [sliderValue]);




  // NORMAL STUFF HAPPENS AGAIN

  const handlePaperClick = () => {
    setIsFlipped(!isFlipped);
    setDisplayContent('');  // Clear display content when flipping the paper
};

useEffect(() => {
  console.log("Current Index =", currentIndex);
  localStorage.setItem(`learningModeIndex-${questionSetId}`, currentIndex);
}, [currentIndex, questionSetId]);


const handleNextClick = () => {
  console.log("Next Click: Current Index =", currentIndex);
  if (currentIndex < currentQuestions.length - 1) {
    setCurrentIndex(prevIndex => prevIndex + 1);
  } else {
    setCurrentIndex(0); // Go back to the first card
    console.log("After Next Click: Reached end, going to first card");
  }
  setIsFlipped(false);
};

const handlePrevClick = () => {
  console.log("Prev Click: Current Index =", currentIndex);
  if (currentIndex > 0) {
    setCurrentIndex(prevIndex => prevIndex - 1);
  } else {
    setCurrentIndex(currentQuestions.length - 1); // Go back to the last card
    console.log("After Prev Click: Reached start, going to last card");
  }
  setIsFlipped(false);
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevClick();
    } else if (event.key === 'ArrowRight') {
      handleNextClick();
    }
  };

  if (open) {
    window.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [open, handlePrevClick, handleNextClick]);



const handleQuoteClick = (e) => {
  e.stopPropagation(); // Prevent event bubbling


  // Safely retrieve quote content, file name, and page number
  const quoteContent = currentQuestion?.answer?.quote?.content || null;
  const fileName = currentQuestion?.source_text_detail?.file?.file_name || null;
  const pageNumber = currentQuestion?.source_text_detail?.page_number || null;

  // Check if all required details are available
  if (quoteContent && fileName && pageNumber !== undefined) {
    const newDisplayContent = `${quoteContent} (${fileName}, S. ${pageNumber})`;
    console.log({ quoteContent, fileName, pageNumber });

    // Toggle visibility or update content
    if (displayContent === newDisplayContent && contentType === 'quote') {
      setDisplayContent(''); // Clear content
      setContentType(''); // Reset type
    } else {
      setDisplayContent(newDisplayContent); // Update content
      setContentType('quote'); // Set type to 'quote'
    }
  } else {
    // Handle missing data gracefully
    setDisplayContent('Entschuldige, kein Zitat für diese Frage verfügbar.');
    setContentType('quote');
  }
};



const emojis = ["🌟", "📘", "🌈", "🍂", "🌼", "🌙", "✨", "🖋️", "📜"];

const handlePoemClick = (e) => {
  e.stopPropagation(); // Prevent event from bubbling up

  const poemContent =
    questions[currentIndex].answer &&
    questions[currentIndex].answer.memory_poem
      ? questions[currentIndex].answer.memory_poem.content
      : '';

  if (poemContent) {
    const formattedPoem = poemContent.split(/\\n|\n/);

    if (isPoemVisible && contentType === 'poem') {
      // If already visible, toggle off
      setDisplayContent('');
      setIsPoemVisible(false);
      setContentType('');
    } else {
      // Set poem content and make it visible
      setDisplayContent(formattedPoem);
      setIsPoemVisible(true);
      setContentType('poem');
    }
  } else {
    // Handle case where poem content is not available
    setDisplayContent(['Für diese Frage ist kein Gedicht vorhanden.']);
    setIsPoemVisible(true);
    setContentType('poem');
  }
};



const getSmileyIcon = (status) => {
  switch (status) {
    case 'happy':
      return HappySmiley;
    case 'okay':
      return NeutralSmiley;
    case 'sad':
      return SadSmiley;
    default:
      return null; // No icon for 'none' or undefined status
  }
};


const flashcardStatus = currentQuestion ? currentQuestion.flashcard_status : null;
const SmileyIcon = getSmileyIcon(flashcardStatus);



  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',  
        alignItems: 'center',  
        height: '100vh',
        background: theme.palette.gradient.background
      }}>
        <Paper elevation={3} sx={{
          borderRadius: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.97)',
          boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 30px)', 
          width: 'calc(100vw - 20px)', 
        }}>
          {/* Non-scrolling Header */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 4,
            width: '100%',
            backgroundColor: 'transparent',
          }}>
            <Typography sx={{ flex: 1, color: "grey" }} variant="h6" component="div">
              {questionSetName || 'Loading...'}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{ width: 30, height: 30, padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        
      {/* Content Container for both Paper and Buttons */}
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, marginTop: 2}}>
    <div className={`flip-container ${isWiggling ? 'wiggle' : ''}`} onClick={handlePaperClick}>
      <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
      <Paper
      className="front paper-content"
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        justifyContent: 'space-between', // Space between items
        alignItems: 'center',
        borderRadius: "20px", 
      }}
    >
      {SmileyIcon && (
        <SmileyIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            width: 50,
            height: 50,
            color: theme.palette.primary.main,
          }}
        />
      )}
    
      {/* Content Container */}
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        {currentQuestion ? (
          <Typography variant="h5" component="div" align="center" sx={{ fontSize: '1.8em' }}>
            {currentQuestion.content.split(/\/n|\\n/).map((line, idx) => (
              <React.Fragment key={idx}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="div" align="center" sx={{ fontSize: '1.8em' }}>
            Oops! Keine Frage verfügbar. 
          </Typography>
          <Typography variant="h5" component="div" sx={{ mt:2 }}>
          Bitte überprüfe deine Filtereinstellungen oder versuche es später erneut.
        </Typography>
        </Box>
        )}
      </Box>
    {currentQuestion && (
      <Box sx={{ display: 'flex', justifyContent: 'center', mb:2 }}
        onClick={(event) => event.stopPropagation()} 
        >
        <Box sx={{  }}>
          <SadSmileyButton
            questionId={currentQuestion.id}
            onStatusUpdate={updateFlashcardStatus}
            defaultColor="#d1d1d1"
          />
        </Box>
        <Box sx={{ ml: 30, mr: 30}}>
          <NeutralSmileyButton
            questionId={currentQuestion.id}
            onStatusUpdate={updateFlashcardStatus}
            defaultColor="#d1d1d1"
          />
        </Box>
        <Box sx={{  }}>
          <HappySmileyButton
            questionId={currentQuestion.id}
            onStatusUpdate={updateFlashcardStatus}
            defaultColor="#d1d1d1"
          />
        </Box>
      </Box>
    )}
    {/* ZE BACK STARTS HEEEEREEEEE */}
    </Paper>
    <Paper
      className="back paper-content"
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        borderRadius: '20px',
      }}
    >
      {SmileyIcon && (
        <SmileyIcon
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            width: 50,
            height: 50,
            color: theme.palette.primary.main,
          }}
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          align="center"
          sx={{ fontSize: '1.1em', mb: 2 }}
        >
          {currentQuestion && currentQuestion.answer && currentQuestion.answer.content ? (
        currentQuestion.answer.content
          .split(/\/n|\\n/)
          .map((line, idx) => (
            <React.Fragment key={idx}>
              {line}
              <br />
            </React.Fragment>
          ))
      ) : (
        'No answer available'
      )}
    </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            mt: 2,
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mx: 1 }}
            onClick={(e) => handleQuoteClick(e)}
          >
            Quelle
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mx: 1 }}
            onClick={(e) => handlePoemClick(e)}
          >
            Gedicht
          </Button>
        </Box>
        <Typography
  variant="subtitle1"
  component="div"
  align="center"
  sx={{
    mt: 4,
    fontFamily: "'Nunito', sans-serif",
    fontStyle: 'italic',
    fontSize: contentType === 'quote' ? '1em' : 'inherit',
  }}
>
  {contentType === 'quote' && displayContent}
</Typography>

        <Typography
  variant="subtitle1"
  component="div"
  align="center"
  sx={{
    mt: 4,
    fontFamily: contentType === 'poem' ? "'Dancing Script', cursive" : 'inherit',
    fontSize: contentType === 'poem' ? '1.2em' : 'inherit',
  }}
  className={contentType === 'poem' ? 'sparkly-text' : ''}
>
  {Array.isArray(displayContent) &&
    displayContent.map((line, idx) => (
      <React.Fragment key={idx}>
        {line}
        <br />
      </React.Fragment>
    ))}
</Typography>

      </Box>
      {currentQuestion && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
          onClick={(event) => event.stopPropagation()}
        >
          <Box sx={{ }}>
            <SadSmileyButton
              questionId={currentQuestion.id}
              onStatusUpdate={updateFlashcardStatus}
              defaultColor="#d1d1d1"
            />
          </Box>
          <Box sx={{ ml: 30, mr: 30 }}>
            <NeutralSmileyButton
              questionId={currentQuestion.id}
              onStatusUpdate={updateFlashcardStatus}
              defaultColor="#d1d1d1"
            />
          </Box>
          <Box sx={{  }}>
            <HappySmileyButton
              questionId={currentQuestion.id}
              onStatusUpdate={updateFlashcardStatus}
              defaultColor="#d1d1d1"
            />
          </Box>
        </Box>
      )}
    </Paper>
    
          </div>
        </div>
        {/* Button Container directly below the paper */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <IconButton
          color="secondary"
          onClick={handlePrevClick}
          sx={{
            mx: 8,
            width: 56,
            height: 56,
          }}
        >
          <Icon iconName= "leftCircle" style={{ fontSize: '2rem' }} />
        </IconButton>
    
        {/* Current Question / Total Questions */}
        <Typography variant="subtitle1" sx={{ userSelect: 'none' }}>
          {`${currentIndex + 1}/${currentQuestions.length}`}
        </Typography>
    
        <IconButton
          color="secondary"
          onClick={handleNextClick}
          sx={{
            mx: 8,
            width: 56,
            height: 56,
          }}
        >
          <Icon iconName="rightCircle" style={{ fontSize: '2rem' }} />
        </IconButton>
      </Box>
      
      {/* Shuffle Button and Menus */}
      <Box sx={{ mt: 2 }}>
        {/* ShuffleButton has been moved to QuizMenu, remove it from here */}
        {/* <ShuffleButton isShuffled={isShuffled} toggleShuffle={toggleShuffle} /> */}

        {/* QuizMenu */}
        <Box sx={{
          position: 'absolute',
          bottom: 30, // Adjust the value to set the distance from the bottom
          left: 30,   // Adjust the value to set the distance from the left
        }}>
          <QuizMenu 
            totalQuestions={totalAvailableQuestions}
            onCheckboxChange={handleCheckboxChange}
            onSliderChange={setSliderValue}
            sliderValue={sliderValue}
            checkboxState={checkboxState}
            pinnedStateKey={`quizMenuPinned-${questionSetId}`}
            isShuffled={isShuffled}           // New prop
            toggleShuffle={toggleShuffle}     // New prop
          />
        </Box>

        {/* StatisticsMenu */}
        <Box sx={{
          position: 'absolute',
          bottom: 30, // Adjust as needed
          right: 30,   // Position it at the bottom right
        }}>
          <StatisticsMenu 
            questions={questions} 
            pinnedStateKey={`statisticsMenuPinned-${questionSetId}`} // Corrected key
          />
        </Box>
      
      </Box>
    </Box>
      </Box>
      </Paper>
    </Box>
    
        </Dialog>
      );
};

export default LearningMode;
