import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';

const FeedbackDialog = ({ open, onClose, onFeedbackSubmitted }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const location = useLocation();

  const page = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const handleSend = async () => {
    if (feedbackText.trim() === '') {
      onFeedbackSubmitted('Bitte gib dein Feedback ein.');
      return;
    }

    const feedbackData = {
      feedback_text: feedbackText,
      page: page,
      ...(tab && { tab: tab }),
    };

    try {
      const accessToken = localStorage.getItem('access_token');

      const response = await fetch(ENDPOINTS.FEEDBACK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        // Clear the text field
        setFeedbackText('');
        // Close the dialog
        onClose();
        // Inform the parent component
        onFeedbackSubmitted('Danke, dass uns dabei hilfst Thinki noch besser zu machen!');
      } else {
        const errorData = await response.json();
        console.error('Error submitting feedback:', errorData);
        onFeedbackSubmitted('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
      }
    } catch (error) {
      console.error('Network error:', error);
      onFeedbackSubmitted('Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };

  const handleCancel = () => {
    setFeedbackText('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Wir freuen uns über dein Feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Dein Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" sx={{ marginRight: 'auto' }}>
          Abbrechen
        </Button>
        <Button onClick={handleSend} color="primary" variant="contained">
          Senden
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;



