import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '../contexts/ThemeContext'; 
import ProtectedRoute from '../utils/ProtectedRoute';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import LandingPage from '../pages/LandingPage/LandingPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import TosPage from '../pages/TosPage/TosPage';
import MainPage from '../pages/MainPage/MainPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import ConfirmResetPasswordPage from '../pages/ConfirmResetPasswordPage/ConfirmResetPasswordPage';
import CoursePage from '../pages/CoursePage/CoursePage';
import ConfirmEmailPage from '../pages/ConfirmEmailPage/ConfirmEmailPage';
import { AuthProvider } from '../contexts/AuthContext';
import { fetchUserDetails } from '../services/UserService';
import NotFound from '../pages/NotFound';
import AccountActivatedPage from '../pages/AccountActivatedPage/AccountActivatedPage';
import ActivationErrorPage from '../pages/ActivationErrorPage/ActivationErrorPage';
import { Box, Typography } from '@mui/material'; // For showing the mobile message

function App() {
  const [open, setOpen] = useState(true);
  const [userName, setUserName] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Fetch user details if needed
    const loadUserDetails = async () => {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    loadUserDetails();
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <AuthProvider>
      <ThemeProvider>
        <CssBaseline />
        <div className="App">
          {isMobile ? (
            <Box sx={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100vh',
              p: 2
            }}>
              <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
                Bitte verwende Thinki auf einem Desktop.
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Diese App ist für größere Bildschirme optimiert.
              </Typography>
            </Box>
          ) : (
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/tos" element={<TosPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/confirm-reset-password" element={<ConfirmResetPasswordPage />} />
              <Route path="/confirm-email" element={<ConfirmEmailPage />} />
              <Route path="/account-activated" element={<AccountActivatedPage />} />
              <Route path="/error" element={<ActivationErrorPage />} />    
              <Route path="main" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
              <Route path="course/:courseId" element={<ProtectedRoute><CoursePage /></ProtectedRoute>} />
              <Route path="*" element={<NotFound />} /> 
            </Routes>
          )}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;


